import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { H1, H4, H5, MarkdownHandler, Text } from '@system'
import GoogleStructuredData from './google-structured-data'
import ContentContainer from '@system/content-container'
import PageContainer from '@system/page-container'
const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: '0.5rem 0',
  },
  faqTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  faqBody: {
    alignItems: 'flexStart',
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.body1.fontSize,
  },
  subTitle: {
    fontSize: theme.typography.body1.fontSize,
  },
  titleFaqModule: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: ' 1.5rem 0 1rem',
  },
  outroText: {
    fontSize: theme.typography.button.fontSize,
    color: theme.palette.text.tertiary,
    marginBlockStart: '1.67em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
  },
}))

const FaqModule = (props) => {
  const classes = useStyles(props)
  const faqModuleText = props.blok.sections
  const SectionFaqModule = () => {
    return faqModuleText.map((section) => (
      <Box className={classes.faqBody} key={section._uid}>
        <H4 className={classes.titleFaqModule}>{section.sectionTitle}</H4>
        {!!faqModuleText &&
          section.faqs.map((faq) => (
            <>
              <Text component="p" className={classes.description}>
                {faq.question}
              </Text>

              <MarkdownHandler className={classes.answer}>
                {faq.answer}
              </MarkdownHandler>
            </>
          ))}
      </Box>
    ))
  }
  return (
    <SbEditable content={props.blok}>
      <GoogleStructuredData type="FAQPage">
        {props.blok.sections}
      </GoogleStructuredData>
      <PageContainer>
        <ContentContainer>
          <Box className={classes.faqTitle}>
            <H1 className={classes.title}>{props.blok.title}</H1>
            <MarkdownHandler className={classes.subTitle}>
              {props.blok.introText}
            </MarkdownHandler>
          </Box>
          <Box>
            <SectionFaqModule />
            <H5 className={classes.outroText}>{props.blok.outroText}</H5>
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default FaqModule
